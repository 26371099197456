<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <a-input v-model:value="modifierSearchName" placeholder="Поиск по названию" @change="searchModifier" allow-clear />
      </div>
      <div class="col-md-2">
        <a-select
            v-model:value="modifierSearchStatus"
            @change="searchModifierStatus"
            style="width: 100%"
        >
          <a-select-option value="">Выберите статус</a-select-option>
          <a-select-option value="1">Активный</a-select-option>
          <a-select-option value="0">Неактивный</a-select-option>
        </a-select>
      </div>
      <div class="col-md-8 text-right">
        <vb-create-modifier-group :modal="createModal" :key="createModalKey" :locales="locales" @reload="reload()" />
      </div>
    </div>
    <vb-edit-modifier-group
      v-if="editModal"
      :reference-id="modifierId"
      :locales="locales"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
    >
      <template #name="{ record }">
        <a-tree
          :selectable="false"
          :load-data="onLoadLocales"
          v-model:expandedKeys="expandedKeys"
          :tree-data="treeData.find(item => item.id === record.id)?.locales"
        >
          <template #switcherIcon><down-outlined /></template>
        </a-tree>
      </template>
      <template #type="{ record }">
        <div>{{ record.type }}</div>
      </template>
      <template #status="{ record }">
        <a-tag
            @click="updateStatus(record['id'], 0)"
            v-if="record.status"
            color="#108ee9"
            class="cursor-pointer"
        >Активный
        </a-tag>
        <a-tag
            @click="updateStatus(record['id'], 1)"
            class="cursor-pointer"
            v-else
        >Неактивный
        </a-tag>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #edited="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2" v-if="user.role !== 'manager'">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2" v-if="user.role !== 'manager'">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import {watch, computed, reactive, ref, toRefs, onMounted} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VbCreateModifierGroup from "@/views/references/modifier-group/create";
import VbEditModifierGroup from "@/views/references/modifier-group/edit";
import apiClient from "@/services/axios";
import { useStore } from "vuex";
import {message, Modal} from "ant-design-vue";
import {DownOutlined} from '@ant-design/icons-vue';

let
  modifierSearchName = ref(''),
  modifierSearchStatus = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  createModal = ref(false),
  createModalKey = ref(0),
  confirmLoading = ref(false),
  editModal = ref(false),
  locales = ref([]),
  timer = null,
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  total = ref(0),
  modifierId = ref(0),
  treeData = ref([]);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(),
  expandedKeys = ref([]),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      slots: { customRender: 'name' },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      slots: { customRender: 'type' },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      slots: { customRender: 'status' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Изменен',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'edited' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  onLoadLocales = treeNode => {
    return new Promise(resolve => {
      if (treeNode.dataRef.children) {
        resolve();
        return;
      }
      apiClient.get(`/ref-modifier-group/view?id=${treeNode.dataRef.key}`)
        .then(({ data }) => {
          if (data) {
            treeNode.dataRef.children = []
            data.data.locales.forEach((item) => {
              const model = locales.value.find(locale => locale?.short === item.locale)
              treeNode.dataRef.children.push({
                title: `${model.name}: ${item.name}`,
                key: item.id,
                children: [],
              })
            })
          }
        }).catch(err => {
        })
        .finally(() => {
          resolve()
        })
    });
  },
  updateStatus = (id, status) => {
    apiClient.put(`/ref-modifier-group/update-status?id=${id}&status=${status}`).then(response => {
      if (response) {
        message.success('Модификация успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  searchModifier = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ title: e.target.value })
    }, 800);
  },
  searchModifierStatus = (value) => {
    updateParams({ status: value })
  },
  showEditModal = (id) => {
    modifierId.value = id
    editModal.value = true;
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление записи',
      content: `Вы действительно хотите удалить запись?`,
      okText: 'Да',
      onOk() {
        return apiClient.delete(`modifier/delete?id=${id}`).then(response => {
          if (response) {
            message.success('Запись успешно удалена')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  formattedBalance = (balance, penny = true) => {
    if (penny)
      balance = balance / 100;
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance)
    return formattedBalance.substring(0, formattedBalance.length - 4) + ' UZS'
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/ref-modifier-group/index', { params }).then(({data}) => {
      treeData.value = []
      data.data.items.forEach((item) => {
        treeData.value.push({
          id: item.id,
          locales: [{
            title: item.name,
            key: item.id,
          }],
        })
      })
      dataSource.value = data.data.items
      total.value = data.data.total
      locales.value = data.data.locales
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      createModal.value = true
      createModalKey.value++
    }
  });
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'modifier-group',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./index.css"></style>
